import { CartItem } from '../../../../types/Cart';
import { SummaryDetails } from '../Summary/components/SummaryDetails/SummaryDetails';

export interface CartItemDetailsProps {
    selectedCartItem: CartItem;
    onClose: () => void;
}

export const CartItemDetails = ({ selectedCartItem, onClose }: CartItemDetailsProps) => {
    if (selectedCartItem) {
        return <SummaryDetails item={selectedCartItem} onClose={onClose} />;
    }

    return null;
};
