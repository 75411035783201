import { isObjectWithKey } from '../../validateData';

const ProductTaxonCodes = [
    'DOUBLE_HANDLES',
    'WOODEN_DOOR_HANDLES',
    'GLASS_DOOR_HANDLES',
    'HANDLE',
    'SINGLE_HANDLES',
] as const;

type TaxonCodes = typeof ProductTaxonCodes[number];

export type ProductTaxon = {
    taxon: {
        code: TaxonCodes;
    };
};

export function isProductTaxon(data: unknown): data is ProductTaxon {
    return (
        isObjectWithKey(data) &&
        isObjectWithKey(data.taxon) &&
        typeof data.taxon.code === 'string' &&
        ProductTaxonCodes.includes(data.taxon.code as any)
    );
}
