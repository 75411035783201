import styled from 'styled-components';
import { Color } from '../../constants/Color.enum';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 18px;
    margin-top: 66px;

    button div {
        gap: 24px;
    }
`;

export const DividerStyled = styled.div`
    background-color: ${Color.Variant_1};
    height: 1px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
`;
