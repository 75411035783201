import React, { useEffect, useState } from 'react';
import { Button } from '@formify/frontend-components';
import { LinkS } from '@formify/frontend-components';
import { SuccessMessage } from '../../../atoms/Message/SuccessMessage/SuccessMessage';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { saveProjectFormValidator } from './validators/saveProjectFormValidator';
import { TextInputWithButton } from '../../../TextInputWithButton/TextInputWithButton';
import { Message } from '../../../atoms/Message/Message';
import { Tr } from '../../../Translations/Tr';
import { useLanguage } from '../../../Translations/LanguageProvider';

interface SaveProjectFormProps {
    saveCurrentProject(email: string): Promise<any>;
    goBack(): void;
    saved: boolean;
}

export const SaveProjectForm = ({ saveCurrentProject, saved, goBack }: SaveProjectFormProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useLanguage();

    const formMethods = useForm({
        defaultValues: {
            email: '',
        },
        resolver: yupResolver(saveProjectFormValidator),
    });

    const {
        handleSubmit,
        formState: { errors },
        reset,
    } = formMethods;

    const showError = Boolean(errors.email);

    useEffect(() => {
        if (saved) {
            return () => reset();
        }
    }, [saved, reset]);

    return (
        <div>
            {/* <ImageStyled src={savedProjectId ? afterSaveImg : beforeSaveImg} alt="save-img" /> */}
            <LinkS className="mt-32">
                {saved ? (
                    <Tr defaultValue="Your wardrobe is waiting for you" labelKey="savedProjectTitle" />
                ) : (
                    <Tr defaultValue="Receive a link in your email" labelKey="saveProjectTitle" />
                )}
            </LinkS>
            <LinkS className="mt-20 mb-32" fontWeight={300}>
                {saved ? (
                    <Tr
                        defaultValue="Get back to your design and continue editing any time. We'll see you soon!"
                        labelKey="savedProjectDescription"
                    />
                ) : (
                    <Tr
                        defaultValue="We'll send a link to your email that gives you access to your saved design"
                        labelKey="saveProjectDescription"
                    />
                )}
            </LinkS>
            <FormProvider {...formMethods}>
                <form
                    onSubmit={handleSubmit(async ({ email }) => {
                        setIsLoading(true);
                        try {
                            await saveCurrentProject(email);
                            // eslint-disable-next-line no-empty
                        } catch (error) {
                        } finally {
                            setIsLoading(false);
                        }
                    })}
                >
                    <TextInputWithButton
                        blocked={Boolean(saved)}
                        isLoading={isLoading}
                        label={t('Your email')}
                        responsiveWidth
                        name="email"
                        onClear={saved ? reset : undefined}
                        error={showError}
                        success={Boolean(saved)}
                    />
                    {(showError || saved) && (
                        <div className="mb-16">
                            {showError && (
                                <Message error message={errors.email?.message} dataTestId="save-my-design-error" />
                            )}
                            {saved && (
                                <SuccessMessage
                                    successMessage={t('Email sent successfully!')}
                                    dataTestId="email-sent-successfully-message"
                                />
                            )}
                        </div>
                    )}
                    {!saved && (
                        <Button full className="mt-16" variant="primary" dataTestId="submit-save-my-design">
                            <Tr labelKey="Save my design" defaultValue="Save my design" />
                        </Button>
                    )}
                </form>
            </FormProvider>
            {saved && (
                <Button full className="mt-16" variant="primary" onClick={goBack}>
                    <Tr labelKey="Back to home" defaultValue="Back to home" />
                </Button>
            )}
        </div>
    );
};
