import { Button } from '@formify/frontend-components';
import { useCurrentProjectSave } from '../../../hooks/useCurrentProjectSave';
import { useState } from 'react';

export const OpenVRButton = () => {
    const save = useCurrentProjectSave();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Button
            variant="secondary"
            isLoading={isLoading}
            disabled={isLoading}
            icon={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
                    <path
                        fill="currentColor"
                        d="M5,3C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3H5M7,9H10C10.6,9 11,9.5 11,10V15H9.5V13.5H7.5V15H6V10C6,9.5 6.4,9 7,9M13,9H16.5C17.35,9 18,9.65 18,10.5V11.5C18,12.1 17.6,12.65 17.1,12.9L18,15H16.5L15.65,13H14.5V15H13V9M7.5,10.5V12H9.5V10.5H7.5M14.5,10.5V11.5H16.5V10.5H14.5"
                    />
                </svg>
            }
            onClick={async () => {
                try {
                    setIsLoading(true);
                    const { id } = await save(undefined, undefined, 'usdz-and-glb-for-ar');

                    setIsLoading(false);
                    window.location.href = `${location.origin}/vr?projectId=${id}`;
                } catch (error) {
                    setIsLoading(false);
                    throw error;
                }
            }}
        ></Button>
    );
};
