import React, { FC } from 'react';

const styleValue = {
    margin: '-3px',
};

export const BigPlusIcon: FC = () => (
    <svg width="20" height="20" style={styleValue} viewBox="0 0 24 24">
        <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
);
