import React, { FC } from 'react';

export const AddIcon: FC = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.99999 3.20344C5.79864 3.20344 3.20344 5.79864 3.20344 8.99999C3.20344 12.2013 5.79864 14.7965 8.99999 14.7965C12.2013 14.7965 14.7965 12.2013 14.7965 8.99999C14.7965 5.79864 12.2013 3.20344 8.99999 3.20344ZM1.79999 8.99999C1.79999 5.02353 5.02354 1.79999 8.99999 1.79999C12.9764 1.79999 16.2 5.02353 16.2 8.99999C16.2 12.9764 12.9764 16.2 8.99999 16.2C5.02354 16.2 1.79999 12.9764 1.79999 8.99999ZM8.9986 5.51349C9.38615 5.51349 9.70033 5.82766 9.70033 6.21522V8.29837H11.7842C12.1717 8.29837 12.4859 8.61254 12.4859 9.0001C12.4859 9.38765 12.1717 9.70182 11.7842 9.70182H9.70033V11.7852C9.70033 12.1727 9.38615 12.4869 8.9986 12.4869C8.61105 12.4869 8.29688 12.1727 8.29688 11.7852V9.70182H6.21421C5.82665 9.70182 5.51248 9.38765 5.51248 9.0001C5.51248 8.61254 5.82665 8.29837 6.21421 8.29837H8.29688V6.21522C8.29688 5.82766 8.61105 5.51349 8.9986 5.51349Z"
            fill="currentColor"
        />
    </svg>
);
