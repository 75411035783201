import { useCallback, useMemo, useRef } from 'react';

export function useCommandQueue<T extends () => Promise<any>>() {
    const queue = useRef<T[]>([]);
    const peek = useCallback(() => queue.current?.[0], []);

    const run = useCallback(() => {
        const command = peek();

        if (command) {
            command().finally(() => {
                // dequeue
                queue.current.shift();
                setTimeout(run, 1);
            });
        }
    }, [peek]);

    const enqueue = useCallback(
        (e: T) => {
            queue.current.push(e);
            if (queue.current.length === 1) {
                setTimeout(run, 1);
            }
        },
        [run],
    );

    return useMemo(
        () => ({
            peek,
            enqueue,
        }),
        [peek, enqueue],
    );
}
