import React, { FC } from 'react';

export const OptionsIcon: FC = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.50001 10.15C5.13513 10.15 5.65001 9.6351 5.65001 8.99998C5.65001 8.36485 5.13513 7.84998 4.50001 7.84998C3.86488 7.84998 3.35001 8.36485 3.35001 8.99998C3.35001 9.6351 3.86488 10.15 4.50001 10.15ZM9.00001 10.15C9.63513 10.15 10.15 9.6351 10.15 8.99998C10.15 8.36485 9.63513 7.84998 9.00001 7.84998C8.36488 7.84998 7.85001 8.36485 7.85001 8.99998C7.85001 9.6351 8.36488 10.15 9.00001 10.15ZM13.5 10.15C14.1351 10.15 14.65 9.6351 14.65 8.99998C14.65 8.36485 14.1351 7.84998 13.5 7.84998C12.8649 7.84998 12.35 8.36485 12.35 8.99998C12.35 9.6351 12.8649 10.15 13.5 10.15Z"
            fill="currentColor"
        />
    </svg>
);
