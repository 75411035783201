import { useEffect, useState } from 'react';

export function useCheckIframe() {
    const [isIframe, setIsIframe] = useState(false);

    useEffect(() => {
        if (typeof window === 'object') {
            setIsIframe(window !== window.parent);
        }
    }, []);

    return isIframe;
}
