import styled from 'styled-components';
import Breakpoint from '../../constants/Breakpoint.enum';
import { Color } from '../../constants/Color.enum';

export const ActionButtonsWrapperStyled = styled.div`
    padding: 16px;
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;

    & > :not(:last-child) {
        margin-bottom: 8px;
    }

    @media (max-width: ${Breakpoint.Tablet}) {
        flex-direction: row;

        & > :not(:last-child) {
            margin-bottom: 0;
            margin-right: 8px;
        }
    }
`;

export const MobileBasketButtonWrapper = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    border-radius: 36px;
    background-color: ${Color.Surface};
    align-items: center;
    width: max-content;
    padding: 8px 15px;
`;
