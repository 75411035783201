import React, { FC } from 'react';
import { Tooltip } from '../atoms/Tooltip/Tooltip';
import { TooltipWrapper } from './TapColumnInfo.styled';

export const TapColumnInfo: FC = () => <TapCustomInfo text="Tap a closet to edit" />;

export const TapWardrobeInfo: FC = () => <TapCustomInfo text="Tap a closet to unselect" />;

export const TapCustomInfo: FC<{ text: string }> = ({ text }) => (
    <TooltipWrapper>
        <Tooltip text={text} />
    </TooltipWrapper>
);
