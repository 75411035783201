import React, { FC, useCallback, useContext } from 'react';
import { ActionButtonsWrapperStyled, MobileBasketButtonWrapper } from './ActionButtons.styled';
import { DoorIcon } from '../../components/icons/Door/Door';
import { Button } from '@formify/frontend-components';
import {
    hideDoors,
    hideInnerElements,
    showDoors,
    showInnerElements,
} from '@formify/frontend-wardrobe-renderer/dist/modules/actions';
import { useWardrobeDispatch, useRendererSelector } from '@formify/frontend-wardrobe-renderer/dist/modules/context';
import { WithDynamicOffset } from '../WithDynamicOffset/WithDynamicOffset';
import { WardrobePrice } from '../SidePanel/components/WardrobePrice/WardrobePrice';
import { OptionsIcon } from '../icons/Options/Options';
import { useSelector } from 'react-redux';
import { useRouterParams } from '../../hooks/useRouterParams';
import { ConfiguratorQueryParams } from '../../constants/router';
import { getCartItemsCount, getIsAddingProject, getUpdateOrderByAdminSettings } from '../../store/project/selectors';
import { LoadingIcon } from '../icons/Loading/Loading';
import { RootState } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { ProductCountBasket } from '../Header/ProductCountBasket/ProductCountBasket';
import { BigPlusIcon } from '../icons/BigPlus/BigPlus';
import { mainRendererContext } from '../../rendererContexts/mainRendererContext';
import { useCurrentWardrobeAddToCart } from '../../hooks/useCurrentProjectSave';
import { InnerElements } from '../icons/InnerElements/InnerElements';
import { useCheckIframe } from '../../hooks/useCheckIframe';
import { cartContext } from '../CartSidePanel/CartSidePanelProvider';
import { OpenVRButton } from './OpenVRButton/OpenVRButton';
import { TooltipOnHoverContainer } from '../atoms/Tooltip/Tooltip.styled';
import { Tooltip } from '../atoms/Tooltip/Tooltip';
import { useLanguage } from '../Translations/LanguageProvider';

export const getDoorVisibleThreeJsSelector = (state: RootState) => state.doorVisible;
export const getInnerItemsVisibleThreeJsSelector = (state: RootState) => state.innerItemsVisible;

const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
};

export const ActionButtons: FC = () => {
    const dispatchWardrobe = useWardrobeDispatch(mainRendererContext);
    const doorVisible = useRendererSelector(getDoorVisibleThreeJsSelector, mainRendererContext);
    const innerItemsVisible = useRendererSelector(getInnerItemsVisibleThreeJsSelector, mainRendererContext);
    const { setParam } = useRouterParams<ConfiguratorQueryParams>();
    const isAddingProductToCart = useSelector(getIsAddingProject);
    const cartItemsCount = useSelector(getCartItemsCount);
    const showCart = useSelector(getUpdateOrderByAdminSettings) === null;
    const addWardrobeAddToCart = useCurrentWardrobeAddToCart();
    const isIframe = useCheckIframe();
    const { openCart } = useContext(cartContext);

    const handleAdd = useCallback(async () => {
        if (isAddingProductToCart) {
            return;
        }
        const openCartAfter = cartItemsCount === 0;

        await addWardrobeAddToCart();
        openCartAfter && openCart();
    }, [addWardrobeAddToCart, cartItemsCount, isAddingProductToCart, openCart]);

    const { t } = useLanguage();

    return (
        <>
            <WithDynamicOffset>
                <ActionButtonsWrapperStyled data-test="action_buttons">
                    <div hidden={isIframe}>
                        <TooltipOnHoverContainer>
                            <Tooltip text={t('common.menu.actionButtonLabel', 'Open menu')} />
                            <Button
                                variant="secondary"
                                icon={<OptionsIcon />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setParam('option', 'menu');
                                }}
                                data-test="dots_button"
                            />
                        </TooltipOnHoverContainer>
                    </div>
                    <div className="hidden md:flex">
                        <TooltipOnHoverContainer>
                            <Tooltip text={t('wardrobe.doors.actionButtonLabel', 'Toggle doors')} />
                            <Button
                                variant={doorVisible ? 'primary' : 'secondary'}
                                icon={<DoorIcon />}
                                onClick={() => dispatchWardrobe(doorVisible ? hideDoors() : showDoors())}
                                data-test="door_button"
                                data-test-state={doorVisible ? 'shown' : 'hidden'}
                            ></Button>
                        </TooltipOnHoverContainer>
                    </div>

                    <div className="hidden md:flex">
                        <TooltipOnHoverContainer>
                            <Tooltip text={t('wardrobe.clothes.actionButtonLabel', 'Toggle clothes')} />
                            <Button
                                variant={innerItemsVisible ? 'primary' : 'secondary'}
                                icon={<InnerElements />}
                                onClick={() =>
                                    dispatchWardrobe(innerItemsVisible ? hideInnerElements() : showInnerElements())
                                }
                                data-test="clothes_button"
                                data-test-state={innerItemsVisible ? 'shown' : 'hidden'}
                            ></Button>
                        </TooltipOnHoverContainer>
                    </div>

                    <div className="hidden md:flex">
                        <TooltipOnHoverContainer>
                            <Tooltip text={t('wardrobe.AR.actionButtonLabel', 'Generate AR preview')} />
                            <OpenVRButton />
                        </TooltipOnHoverContainer>
                    </div>

                    <div className="flex  md:hidden">
                        <OpenVRButton />
                    </div>

                    <div className="flex md:hidden" onClick={stopPropagation}>
                        <div onClick={handleAdd}>
                            <a>
                                <MobileBasketButtonWrapper>
                                    <div className="mt-2 mr-8">
                                        <WardrobePrice small />
                                    </div>

                                    {isAddingProductToCart ? <LoadingIcon /> : <BigPlusIcon />}
                                </MobileBasketButtonWrapper>
                            </a>
                        </div>
                    </div>

                    {cartItemsCount !== 0 && showCart && isIframe === false && (
                        <TooltipOnHoverContainer>
                            <Tooltip text={t('common.cart.actionButtonLabel', 'Open cart')} />
                            <Button
                                variant="secondary"
                                icon={<ProductCountBasket />}
                                onClick={() => openCart()}
                                data-test="open-cart"
                            ></Button>
                        </TooltipOnHoverContainer>
                    )}
                </ActionButtonsWrapperStyled>
            </WithDynamicOffset>
        </>
    );
};
