import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouterParams } from '../../hooks/useRouterParams';
import { Tabs } from '../atoms/Tabs/Tabs';
import { OptionName, OPTION_ORDER_MOBILE, ALL_OPTIONS } from '../SidePanel/constants';
import { ConfiguratorQueryParams } from '../../constants/router';
import { useAvailableOptions } from '../../hooks/useVisibleOptions';
import {
    columnClick,
    hideDoors,
    hideInnerElements,
    showDoors,
    showInnerElements,
    wallClick,
} from '@formify/frontend-wardrobe-renderer/dist/modules/actions';
import { useWardrobeDispatch, useWardrobeSubscribe } from '@formify/frontend-wardrobe-renderer/dist/modules/context';
import { Summary } from '../SidePanel/components/Summary/Summary';
import { mainRendererContext } from '../../rendererContexts/mainRendererContext';
import { useSelector } from 'react-redux';
import {
    getPreviousSelectedColumnIndex,
    getSelectedColumnIndex,
    getVisibleColumnWall,
} from '../../store/wardrobe/selectors';
import { setSelectedColumnIndex } from '../../store/wardrobe/actions';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { TapColumnInfo, TapCustomInfo, TapWardrobeInfo } from '../TapColumnInfo/TapColumnInfo';
import { useStore } from 'react-redux';
import { RootState } from '../../store';
import { DetailsContent, DetailsContentElement } from '../SidePanel/components/Details';
import { Button } from '@formify/frontend-components';
import { getIsAddingProject } from '../../store/project/selectors';
import { useCurrentWardrobeAddToCart } from '../../hooks/useCurrentProjectSave';
import { cartContext } from '../CartSidePanel/CartSidePanelProvider';
import { Tr } from '../Translations/Tr';
import { useLanguage } from '../Translations/LanguageProvider';

const MobileMenu: FC = () => {
    const [details, setDetails] = useState<DetailsContentElement | null>(null);
    const { setParam, params } = useRouterParams<ConfiguratorQueryParams>();
    const option = params.option || 'wardrobeType';
    const dispatchRenderer = useWardrobeDispatch(mainRendererContext);
    const { dispatch, getState } = useStore<RootState, any>();
    const isUShapeOrLShape = useSelector(getVisibleColumnWall('B')).length !== 0;
    const { t } = useLanguage();

    const selectedStateInCurrentActiveOption = useMemo(() => {
        const selectedOption = OPTION_ORDER_MOBILE.includes(option as any)
            ? option === 'menu'
                ? undefined
                : option
            : undefined;

        if (!selectedOption) {
            return 'columnOrWardrobe';
        }

        return ALL_OPTIONS[selectedOption].selectedStateRequire;
    }, [option]);

    const onSelectTab = (tabName: OptionName) => {
        setParam('option', tabName);

        const { doorVisible, insideElementsVisible, selectedStateRequire } = ALL_OPTIONS[tabName];

        const requireChangeSelection =
            (selectedColumnIndex === null && selectedStateRequire === 'column') ||
            (selectedColumnIndex !== null && selectedStateRequire === 'wardrobe');

        if (requireChangeSelection) {
            if (selectedColumnIndex === null) {
                const state = getState();
                const previousSelectedColumnIndex = getPreviousSelectedColumnIndex(state);

                if (previousSelectedColumnIndex !== null) {
                    dispatch(setSelectedColumnIndex(previousSelectedColumnIndex));
                } else {
                    dispatch(setSelectedColumnIndex(0));
                }
            } else {
                dispatch(setSelectedColumnIndex(null));
            }
        }

        if (doorVisible === 'never') {
            dispatchRenderer(hideDoors());
        } else if (doorVisible === 'always') {
            dispatchRenderer(showDoors());
        }

        if (insideElementsVisible === 'never') {
            dispatchRenderer(hideInnerElements());
        } else if (insideElementsVisible === 'always') {
            dispatchRenderer(showInnerElements());
        }
    };

    const allOptionVisibilityStatus = useAvailableOptions();
    const selectedColumnIndex = useSelector(getSelectedColumnIndex);

    useWardrobeSubscribe(
        () => {
            if (selectedColumnIndex !== null) {
                if (
                    selectedStateInCurrentActiveOption === 'columnOrWardrobe' ||
                    selectedStateInCurrentActiveOption === 'wardrobe'
                ) {
                    dispatch(setSelectedColumnIndex(null));
                }
            }
        },
        wallClick,
        mainRendererContext,
    );

    const handlerColumnClick = useCallback(
        (clickedIndexAction: { payload: number }) => {
            const clickedIndex = clickedIndexAction.payload;

            if (selectedColumnIndex === clickedIndex) {
                if (
                    selectedStateInCurrentActiveOption === 'columnOrWardrobe' ||
                    selectedStateInCurrentActiveOption === 'wardrobe'
                ) {
                    dispatch(setSelectedColumnIndex(null));
                }
            } else {
                if (
                    selectedStateInCurrentActiveOption === 'columnOrWardrobe' ||
                    selectedStateInCurrentActiveOption === 'column'
                ) {
                    dispatch(setSelectedColumnIndex(clickedIndex));
                }
            }
        },
        [dispatch, selectedColumnIndex, selectedStateInCurrentActiveOption],
    );

    useWardrobeSubscribe(handlerColumnClick, columnClick, mainRendererContext);

    useEffect(() => {
        if (selectedStateInCurrentActiveOption === 'wardrobe' && selectedColumnIndex !== null) {
            dispatch(setSelectedColumnIndex(null));
        } else if (selectedStateInCurrentActiveOption === 'column' && selectedColumnIndex === null) {
            const state = getState();
            const previousSelectedColumnIndex = getPreviousSelectedColumnIndex(state);

            if (previousSelectedColumnIndex !== null) {
                dispatch(setSelectedColumnIndex(previousSelectedColumnIndex));
            } else {
                dispatch(setSelectedColumnIndex(0));
            }
        }
    }, [dispatch, getState, option, selectedColumnIndex, selectedStateInCurrentActiveOption]);

    const addWardrobeAddToCart = useCurrentWardrobeAddToCart();
    const isAddingProductToCart = useSelector(getIsAddingProject);
    const { openCart } = useContext(cartContext);

    const handleAdd = useCallback(async () => {
        if (isAddingProductToCart) {
            return;
        }

        await addWardrobeAddToCart();
        openCart();
    }, [addWardrobeAddToCart, isAddingProductToCart, openCart]);

    return (
        <>
            <Tabs
                items={OPTION_ORDER_MOBILE.reduce<{ label: string; value: OptionName }[]>((acc, key) => {
                    if (
                        !isUShapeOrLShape &&
                        (key === 'measurementsA' || key === 'measurementsB' || key === 'measurementsC')
                    ) {
                        return acc;
                    }
                    const { isVisibleMobile, selectedStateRequire, label } = ALL_OPTIONS[key];

                    if (typeof isVisibleMobile === 'function') {
                        const visible = allOptionVisibilityStatus[key];

                        if (isVisibleMobile(getState(), visible)) {
                            acc.push({
                                label: t(label),
                                value: key,
                            });
                        }
                    } else {
                        const visible = allOptionVisibilityStatus[key];

                        const requireChangeSelection =
                            (selectedColumnIndex === null && selectedStateRequire === 'column') ||
                            (selectedColumnIndex !== null && selectedStateRequire === 'wardrobe');

                        if (visible || requireChangeSelection) {
                            acc.push({
                                label: t(label),
                                value: key,
                            });
                        }
                    }

                    return acc;
                }, [])}
                selectedTab={
                    OPTION_ORDER_MOBILE.includes(option as any) ? (option === 'menu' ? undefined : option) : undefined
                }
                onSelect={onSelectTab}
            >
                <Button size="small" onClick={handleAdd} isLoading={isAddingProductToCart}>
                    <span className="whitespace-nowrap uppercase">
                        <Tr labelKey="Add to cart" defaultValue="Add to cart" />
                    </span>
                </Button>
            </Tabs>

            {OPTION_ORDER_MOBILE.map((key) => {
                const Component = ALL_OPTIONS[key].EditComponentMobile;
                const { selectedStateRequire, getMobileTextIfOptionIsNotVisible, isVisibleMobile } = ALL_OPTIONS[key];
                const state = getState();

                const visible =
                    typeof isVisibleMobile === 'function'
                        ? isVisibleMobile(state, allOptionVisibilityStatus[key])
                        : allOptionVisibilityStatus[key];

                const requireChangeSelection =
                    (selectedColumnIndex === null && selectedStateRequire === 'column') ||
                    (selectedColumnIndex !== null && selectedStateRequire === 'wardrobe');

                const text =
                    typeof getMobileTextIfOptionIsNotVisible === 'function'
                        ? getMobileTextIfOptionIsNotVisible(state)
                        : null;

                return (
                    <div hidden={option !== key} key={key}>
                        <div hidden={!visible || text !== null}>
                            <Component onOpenDetails={setDetails} isOpen={option === key && visible && text === null} />
                        </div>
                        {text !== null ? (
                            <TapCustomInfo text={text} />
                        ) : visible ? null : requireChangeSelection ? (
                            selectedColumnIndex === null ? (
                                <TapColumnInfo />
                            ) : (
                                <TapWardrobeInfo />
                            )
                        ) : null}
                    </div>
                );
            })}

            <Summary
                isOpen={params.option === 'summary'}
                onClose={() => {
                    onSelectTab('main');
                }}
            />
            <ContextMenu onClose={() => setParam('option', 'main')} isOpen={params.option === 'menu'} />

            {details !== null && (
                <DetailsContent
                    element={details}
                    onClose={() => {
                        setDetails(null);
                    }}
                />
            )}
        </>
    );
};

export default MobileMenu;
