import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouterParams } from '../../hooks/useRouterParams';
import { ScrollableContentInSidePanel } from '../Sidebar/components/ScrollableContentInSidePanel/ScrollableContentInSidePanel';
import { ConfiguratorQueryParams } from '../../constants/router';
import { ALL_OPTIONS, isOptionName } from './constants';
import { useActor } from '@xstate/react';
import { useWardrobeSubscribe } from '@formify/frontend-wardrobe-renderer/dist/modules/context';
import { configMachine } from './machines/configMachine';
import { setSelectedColumnIndex } from '../../store/wardrobe/actions';
import { columnClick, wallClick } from '@formify/frontend-wardrobe-renderer/dist/modules/actions';
import { mainRendererContext } from '../../rendererContexts/mainRendererContext';
import { InterpreterFrom } from 'xstate';
import { SidePanelSteps } from './SidePanelSteps';
import { DetailsContent, DetailsContentElement } from './components/Details';
import { CarouselIndexes, SliderWithDetails } from './components/SliderWithDetails/SliderWithDetails';
import { getSelectedColumnIndex } from '../../store/wardrobe/selectors';
import { useStore } from 'react-redux';
import { RootState } from '../../store';
import { useLoadProject } from '../../hooks/useLoadProject';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { pushEvent } from '../../services/analytics/analytics';
import { configMachineServiceContext } from '../ConfiguratorStateMachineProvider/ConfiguratorStateMachineProvider';
import { cartContext } from '../CartSidePanel/CartSidePanelProvider';

const SidePanelWrapper = () => {
    const { configMachineService } = useContext(configMachineServiceContext);

    if (configMachineService === null) {
        return null;
    }

    return <SidePanel configMachineService={configMachineService} />;
};

interface SidePanelProps {
    configMachineService: InterpreterFrom<typeof configMachine>;
}

const SidePanel = (props: SidePanelProps) => {
    const { registerOnCloseHandler } = useContext(cartContext);
    const { setParam, params } = useRouterParams<ConfiguratorQueryParams>();
    const { dispatch, getState } = useStore<RootState, any>();
    const loadProject = useLoadProject();
    const [current, send] = useActor(props.configMachineService);
    const option = isOptionName(current.value) ? current.value : 'init';
    const [selectedSlideIndex, setSelectedSlideIndex] = useState(CarouselIndexes.Main);
    const [subDetailsIsVisible, setSubDetailsIsVisible] = useState<boolean>(false);

    const configurator_step = useMemo(() => {
        if (option === 'columnSetting') {
            const selectedColumn = current.context.selectedColumn;

            return `columnSetting${selectedColumn}`;
        }

        return option;
    }, [current.context.selectedColumn, option]);

    useEffect(() => {
        pushEvent('configurator_step', {
            configurator_step,
        });
    }, [configurator_step]);

    const [details, setDetails] = useState<DetailsContentElement>({
        type: 'media',
        props: {
            details: null,
        },
    });

    useWardrobeSubscribe(
        () => {
            if (current.value !== 'summary') {
                return;
            }
            const selectedColumnIndex = getSelectedColumnIndex(getState());

            if (selectedColumnIndex !== null) {
                dispatch(setSelectedColumnIndex(null));
            }
        },
        wallClick,
        mainRendererContext,
    );

    const handlerColumnClick = useCallback(
        (clickedIndexAction: { payload: number }) => {
            const clickedIndex = clickedIndexAction.payload;

            if (current.value !== 'summary') {
                return;
            }
            const selectedColumnIndex = getSelectedColumnIndex(getState());

            setTimeout(() => {
                if (selectedColumnIndex === clickedIndex) {
                    dispatch(setSelectedColumnIndex(null));
                } else {
                    dispatch(setSelectedColumnIndex(clickedIndex));
                }
            }, 0);
        },
        [current.value, dispatch, getState],
    );

    useWardrobeSubscribe(handlerColumnClick, columnClick, mainRendererContext);

    useEffect(
        () =>
            registerOnCloseHandler(() => {
                if (current.value === 'resetWaiting') {
                    loadProject('default');
                    setParam('option', 'main');
                    send({
                        type: 'BACK_TO_CONFIG',
                    });
                } else {
                    setParam('option', 'main');
                }
            }),
        [current.value, loadProject, registerOnCloseHandler, send, setParam],
    );
    const { FooterComponent } = ALL_OPTIONS[option];

    return (
        <>
            <SliderWithDetails
                isOpen={true}
                selectedSlideIndex={selectedSlideIndex}
                details={
                    <DetailsContent
                        element={details}
                        onClose={() => {
                            setSelectedSlideIndex(CarouselIndexes.Main);
                        }}
                    />
                }
            >
                <ScrollableContentInSidePanel sticky={subDetailsIsVisible ? null : <FooterComponent />}>
                    <SidePanelSteps />
                    {Object.entries(ALL_OPTIONS).map(([key, { EditComponent }]) => (
                        <div key={key} hidden={key !== option}>
                            <EditComponent
                                isOpen={key === option}
                                onChangeVisibilitySubDetails={setSubDetailsIsVisible}
                                onOpenDetails={(details) => {
                                    setSelectedSlideIndex(CarouselIndexes.Details);

                                    setDetails(details);
                                }}
                            />
                        </div>
                    ))}
                </ScrollableContentInSidePanel>
            </SliderWithDetails>
            <ContextMenu onClose={() => setParam('option', 'main')} isOpen={params.option === 'menu'} />
        </>
    );
};

export default SidePanelWrapper;
