import styled from 'styled-components';
import Breakpoint from '../../constants/Breakpoint.enum';

export const TooltipWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: 94px;
    transform: translate(-50%, 0);

    @media (max-width: ${Breakpoint.Tablet}) {
        position: fixed;
    }
`;
