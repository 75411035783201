import styled from 'styled-components';
import { ImageWithShimmerEffect } from '../../../ImageWithShimmerEffect/ImageWithShimmerEffect';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    margin: 0 28px 28px 28px;
`;

export const BackButtonWrapper = styled.div`
    position: absolute;
    padding: 16px;
`;

export const ImageStyled = styled(ImageWithShimmerEffect)`
    border-radius: 10px;
    height: 400px;
`;
