import { isProductCodes } from './domain/ProductCodes';
import { Product, Translation } from '../../types/Product';
import {
    isObjectWithKey,
    getStringArray,
    getString,
    getNumber,
    getGenericArray,
    getGenericValue,
    isImage,
} from '../validateData';
import {
    OptionSelect,
    OptionSelectRaw,
    ThreeDParameter,
    ThreeDParameterRaw,
    isCustomOptionRaw,
} from './domain/ThreeDParameter';
import { getPublicEnv } from '../env/env';
import { FetchService, fetchUnAuthorizationApi } from '../fetch/fetch';
import { isProductTaxon } from './domain/ProductTaxons';
import { TranslationService } from '../translation/translation';
import { StaticAuth } from '../auth/StaticAuth';

const createBuilderTranslateObject = async () => {
    const auth = new StaticAuth(null);
    const fetch = new FetchService(auth);
    const translationService = new TranslationService(fetch);
    const allLanguages = await translationService.getAvailableLanguages();

    // for cache
    // await translationService.getNormalizeLabels();

    const builder = async (label: string, defaultValue: string) => {
        const result: { [lang: string]: string } = {};

        await Promise.all(
            allLanguages.map(async ({ code }) => {
                result[code] = await translationService.getLabel(code, label, defaultValue);
            }),
        );

        return result;
    };

    return builder;
};

export const fetchProducts = async (): Promise<Product[]> => {
    const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');
    const mediaDomainUrl = await getPublicEnv('MEDIA_DOMAIN_URL');

    const response = await fetchUnAuthorizationApi(`${apiUrl}/shop/products`, {
        headers: {
            accept: 'application/ld+json',
        },
    });

    if (!isObjectWithKey(response)) {
        throw new Error('Invalid response');
    }

    const products: unknown = response['hydra:member'];

    if (Array.isArray(products)) {
        const tr = await createBuilderTranslateObject();

        const formattedProducts = products.map(
            async (product): Promise<Product> => ({
                type: getString(product['@type']),
                path: getString(product['@id']),
                id: getNumber(product.id, -1),
                code: getGenericValue(product.code, isProductCodes, 'unknown'),
                threeDParameters: await Promise.all(
                    getGenericArray(product.threeDParameters, isCustomOptionRaw).map(
                        async (option: ThreeDParameterRaw): Promise<ThreeDParameter> => {
                            if (option.type === 'INT') {
                                return option;
                            }
                            if (option.type === 'SELECT') {
                                return {
                                    ...option,
                                    optionSelects: await Promise.all(
                                        option.optionSelects.map(
                                            async (optionSelect: OptionSelectRaw): Promise<OptionSelect> => ({
                                                ...optionSelect,
                                                name: await tr(
                                                    `productOption.${option.name}.name.${optionSelect.value}`,
                                                    optionSelect.name,
                                                ),
                                                description: await tr(
                                                    `productOption.${option.name}.description.${optionSelect.value}`,
                                                    optionSelect.description || '',
                                                ),
                                                image: {
                                                    ...optionSelect.image,
                                                    path: `${mediaDomainUrl}${optionSelect.image.path}`,
                                                },
                                            }),
                                        ),
                                    ),
                                };
                            }

                            return option;
                        },
                    ),
                ),
                productTaxons: getGenericArray(product.productTaxons, isProductTaxon),
                mainTaxon: getString(product.mainTaxon),
                description: getString(product.description),
                reviews: getStringArray(product.reviews),
                images: getGenericArray(product.images, isImage).map((image) => ({
                    ...image,
                    path: mediaDomainUrl + getString(image.path),
                })),
                variants: getStringArray(product.variants),
                options: getStringArray(product.options),
                createdAt: getString(product.createdAt),
                updatedAt: getString(product.updatedAt),
                name: await tr(`product.name.${product.code}`, product.code),
            }),
        );

        return Promise.all(formattedProducts);
    }

    return [];
};
