import React, { FC } from 'react';

export const SaveIcon: FC = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.3672 11.2755V14.5372C16.3672 14.6021 16.3579 14.6625 16.344 14.7181C16.279 15.0383 15.996 15.2795 15.662 15.2795H2.33236C1.97511 15.2795 1.67817 15.0058 1.64105 14.6578C1.63177 14.6161 1.63177 14.5789 1.63177 14.5325V11.2709C1.63177 10.8858 1.94727 10.575 2.32772 10.575C2.51794 10.575 2.69425 10.6538 2.81952 10.7791C2.94479 10.9044 3.02366 11.0807 3.02366 11.2709V13.883H14.98V11.2709C14.98 10.8858 15.2954 10.575 15.6759 10.575C15.8661 10.575 16.0424 10.6538 16.1677 10.7791C16.2883 10.909 16.3672 11.0853 16.3672 11.2755Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.1"
        />
        <path
            d="M12.6694 8.35289L9.59358 11.4287C9.58894 11.438 9.57966 11.4427 9.57502 11.4473C9.44975 11.5726 9.28736 11.6514 9.12498 11.6746C9.11106 11.6746 9.09714 11.6793 9.08322 11.6793C9.05538 11.6839 9.02755 11.6839 8.99971 11.6839L8.92083 11.6793C8.90692 11.6793 8.893 11.6746 8.87908 11.6746C8.71205 11.6514 8.5543 11.5726 8.42903 11.4473C8.4244 11.4427 8.41512 11.4334 8.41048 11.4287L5.31722 8.33393C5.15947 8.17619 5.0806 7.9674 5.0806 7.75862C5.0806 7.54984 5.15947 7.34106 5.31722 7.18331C5.63271 6.86781 6.14771 6.86781 6.46784 7.18331L8.19705 8.91407V2.61192C8.19705 2.16652 8.56358 1.79999 9.00899 1.79999C9.23169 1.79999 9.43583 1.89278 9.5843 2.03661C9.73277 2.18508 9.82092 2.38458 9.82092 2.61192V8.91407L11.5327 7.20226C11.8482 6.88677 12.3632 6.88677 12.6833 7.20226C12.9849 7.5224 12.9849 8.0374 12.6694 8.35289Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.1"
        />
    </svg>
);
