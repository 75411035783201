const availableMediaTypes = [
    'with_doors',
    'split_columns',
    'merge_columns',
    'without_doors',
    '43cm_depth',
    '58cm_depth',
    'glass_doors',
    'without_lighting',
    'with_lighting',
    'without_handle',
    'unknown',
    'kitchen_single_door',
    'kitchen_double_door',
    'kitchen_double_door_and_drawer',
    'kitchen_double_drawer',
    'kitchen_double_drawer_inside',
    'kitchen_empty_layout',
] as const;

export type MediaType = (typeof availableMediaTypes)[number];

export function isMediaType(value: unknown): value is MediaType {
    return typeof value === 'string' && availableMediaTypes.includes(value as MediaType);
}
