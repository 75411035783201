import { Product } from '../../../../types/Product';
import { useLanguage } from '../../../Translations/LanguageProvider';
import { ItemDetails } from '../ItemDetails/ItemDetails';

export interface ProductItemDetailsProps {
    product: Product | undefined;
    onClose: () => void;
}

export const ProductItemDetails = ({ product, onClose }: ProductItemDetailsProps) => {
    const { currentLanguage } = useLanguage();

    if (product) {
        const name = product.name[currentLanguage] || '';

        return (
            <ItemDetails
                key={product.code}
                name={name}
                description={product.description}
                image={product.images[0]?.path}
                onClose={onClose}
            />
        );
    }

    return null;
};
