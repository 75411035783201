import { WardrobeRenderereContextProvider } from '@formify/frontend-wardrobe-renderer/dist/modules/context';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import SidePanelWrapper from '../SidePanel/SidePanel';
import MobileMenu from '../MobileMenu/MobileMenu';
import { renderer3DVisitor } from '../../store/project/actions';
import { SIDEBAR_DESKTOP_WIDTH } from '../Sidebar/Sidebar.styled';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import Breakpoint from '../../constants/Breakpoint.enum';
import { useStore } from 'react-redux';
import { RootState } from '../../store';
import { createWardrobeStructureBasedOnState, getAllProducts } from '../../services/nodes/wardrobe/wardrobe';
import { useRouterParams } from '../../hooks/useRouterParams';
import { ConfiguratorQueryParams } from '../../constants/router';
import dynamic from 'next/dynamic';
import { aoTextures, modelsUrls } from '../TakeScreenshot/ao';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { getSelectedMaterialValue, getVisibleColumnWall } from '../../store/wardrobe/selectors';
import { MATERIALS } from '../SidePanel/components/Frames/useColorOptions';
import { captureException } from '@sentry/nextjs';
import { mainRendererContext } from '../../rendererContexts/mainRendererContext';
import { imageStoreForAllRenderers } from '../../rendererContexts/imageStore';
import { useRouter } from 'next/router';
import { ConfiguratorStateMachineProvider } from '../ConfiguratorStateMachineProvider/ConfiguratorStateMachineProvider';
import { TakeScreenshotProvider } from '../TakeScreenshot/TakeScreenshotProvider';
import { Product } from '../../types/Product';
import { isCustomOptionInt } from '../../services/products/domain/ThreeDParameter';
import { convertValueFromMmToCm } from '../../store/products/utils';
import { DEFAULT_THICKNESS } from '@formify/frontend-wardrobe-renderer/dist/modules/constants';

const WardrobeRenderWrapper = dynamic(() => import('../WardrobeRenderWrapper'), {
    ssr: false,
});

const MainStyled = styled.main`
    display: flex;
    height: 100%;
    position: relative;

    width: calc(100vw - ${SIDEBAR_DESKTOP_WIDTH});
    background: #f5f3f0;

    @media only screen and (max-width: ${Breakpoint.Tablet}) {
        max-height: 100vh;
        width: 100vw;
    }
`;

const getMinimalBoxHeightByProduct = (product: Product | null | undefined, defaultValue: number) => {
    if (product) {
        const option = product.threeDParameters.find((option) => option.genericCode === 'BOX_HEIGHT');

        return isCustomOptionInt(option) ? convertValueFromMmToCm(option.intValidation.min) : defaultValue;
    }

    return defaultValue;
};

export const Editor3D: React.FC<{ children?: React.ReactNode; dragAndDropIsEnable: boolean }> = ({
    children,
    dragAndDropIsEnable,
}) => {
    const { isMobile } = useBreakpoints();
    const store = useStore<RootState>();
    const router = useRouter();
    const { params } = useRouterParams<ConfiguratorQueryParams>();

    const initialState = useMemo(() => {
        const state = store.getState();
        const node = createWardrobeStructureBasedOnState(state);
        const selectedMaterial = getSelectedMaterialValue(state);
        const products = getAllProducts(state);
        const godMod = typeof params.godMod !== 'undefined';
        const isSingleWall = getVisibleColumnWall('B')(state).length + getVisibleColumnWall('C')(state).length === 0;

        const cameraState = isSingleWall
            ? ({
                  type: 'singleWall',
                  position: 'center',
              } as const)
            : ({
                  type: 'uShape',
                  position: 'center',
              } as const);

        return {
            cameraState,
            mode: 'PREVIEW' as const,
            withFrame: 'embedded' as const,
            doorVisible: false,
            innerItemsVisible: false,
            debug: false,
            godMode: godMod,
            columns: node ? renderer3DVisitor.visitWardrobeNode(node) : [],
            focusIndex: null,
            focusWall: null,
            ao: aoTextures,
            modelsUrls: modelsUrls,
            led: false,
            materialSetting:
                selectedMaterial !== null
                    ? MATERIALS[selectedMaterial]
                    : {
                          color: 'white',
                          maskColor: 'white',
                          metalColor: 'white',
                          handleColor: 'white',
                      },
            dragMode: dragAndDropIsEnable,
            minimalBoxHeights: {
                drawer_box_double: getMinimalBoxHeightByProduct(products?.double_drawer, 60),
                drawer_box_quadruple: getMinimalBoxHeightByProduct(products?.quad_drawer, 90),
                glass_shelve: getMinimalBoxHeightByProduct(products?.glass_shelve, 15),
                hanging_rod: getMinimalBoxHeightByProduct(products?.hanger, 80),
                hanging_rod_front: getMinimalBoxHeightByProduct(products?.hanger_front, 80),
                hanging_rod_front_with_shelve:
                    getMinimalBoxHeightByProduct(products?.hanger_front, 80) + DEFAULT_THICKNESS,
                shelve: getMinimalBoxHeightByProduct(products?.shelve, 15),
                space: 0,
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    return (
        <WardrobeRenderereContextProvider
            key={(router?.query?.projectId || 'default').toString()}
            imageStore={imageStoreForAllRenderers}
            initialState={initialState}
            context={mainRendererContext}
        >
            <TakeScreenshotProvider>
                <ConfiguratorStateMachineProvider>
                    {children}
                    <MainStyled>
                        <WardrobeRenderWrapper
                            showStats={false}
                            context={mainRendererContext}
                            onError={(error: any) => {
                                captureException(error);
                            }}
                        />
                        <div className="fixed md:hidden z-100 h-full">
                            {isMobile && <MobileMenu key="mobile-menu" />}
                        </div>
                        <ActionButtons />
                        <div className="hidden md:flex">{!isMobile && <SidePanelWrapper key="desktop-menu" />}</div>
                    </MainStyled>
                </ConfiguratorStateMachineProvider>
            </TakeScreenshotProvider>
        </WardrobeRenderereContextProvider>
    );
};
