import React, { FC } from 'react';

export const DoorIcon: FC = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.59707 3.79454C4.37616 3.79454 4.19707 3.97363 4.19707 4.19454V13.913C4.19707 14.1339 4.37616 14.313 4.59707 14.313H8.3517V3.79454H4.59707ZM4.59707 15.513C3.71341 15.513 2.99707 14.7966 2.99707 13.913V4.19454C2.99707 3.31089 3.71341 2.59454 4.59707 2.59454H13.4C14.2837 2.59454 15 3.31089 15 4.19454V13.913C15 14.7966 14.2837 15.513 13.4 15.513H4.59707ZM9.64538 14.313H13.4C13.6209 14.313 13.8 14.1339 13.8 13.913V4.19454C13.8 3.97363 13.6209 3.79454 13.4 3.79454H9.64538V14.313ZM6.75435 7.88562C6.53104 7.88562 6.35001 8.06665 6.35001 8.28997V9.81605C6.35001 10.0394 6.53104 10.2204 6.75435 10.2204C6.97767 10.2204 7.1587 10.0394 7.1587 9.81605V8.28997C7.1587 8.06665 6.97767 7.88562 6.75435 7.88562ZM11.2544 7.88562C11.031 7.88562 10.85 8.06665 10.85 8.28997V9.81606C10.85 10.0394 11.031 10.2204 11.2544 10.2204C11.4777 10.2204 11.6587 10.0394 11.6587 9.81606V8.28997C11.6587 8.06665 11.4777 7.88562 11.2544 7.88562Z"
            fill="currentColor"
        />
    </svg>
);
