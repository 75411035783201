import styled from 'styled-components';

export const WrapperStyled = styled.div`
    left: 0;
    z-index: 100;
    position: fixed;
    height: 60px;
    background-color: white;
    bottom: 0;
    width: 100vw;
    padding: 0 10px;
`;
