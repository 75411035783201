import React, { useCallback, useContext } from 'react';
import { useCommandQueue } from '../../hooks/useCommandQueue';
import { GetScreenFunction, takeScreenContext } from './takeScreenContext';
import { mainRendererContext } from '../../rendererContexts/mainRendererContext';

// TODO: DELETE THIS FILE
export const TakeScreenshotProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { getImageAsBlob } = useContext(mainRendererContext);
    const queue = useCommandQueue();

    const getScreenshot = useCallback<GetScreenFunction>(
        (config) => {
            const blob = new Promise<Blob>((resolve) => {
                queue.enqueue(async () => {
                    const blob = await getImageAsBlob(config);

                    resolve(blob);
                });
            });

            return blob;
        },

        [getImageAsBlob, queue],
    );

    return (
        <takeScreenContext.Provider
            value={{
                getScreenshot,
            }}
        >
            {children}
        </takeScreenContext.Provider>
    );
};
