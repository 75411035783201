import { CartItemDetails, CartItemDetailsProps } from './CartItemDetails';
import { LayoutItemDetails, LayoutItemDetailsProps } from './LayoutItemDetails';
import { MediaDetails, MediaDetailsProps } from './MediaDetails';
import { OptionSelectItemDetails, OptionSelectItemDetailsProps } from './OptionSelectItemDetails';
import { ProductItemDetails, ProductItemDetailsProps } from './ProductItemDetails';

export type DetailsContentElement =
    | {
          type: 'layout';
          props: Omit<LayoutItemDetailsProps, 'onClose'>;
      }
    | {
          type: 'media';
          props: Omit<MediaDetailsProps, 'onClose'>;
      }
    | {
          type: 'option';
          props: Omit<OptionSelectItemDetailsProps, 'onClose'>;
      }
    | {
          type: 'product';
          props: Omit<ProductItemDetailsProps, 'onClose'>;
      }
    | {
          type: 'cartItem';
          props: Omit<CartItemDetailsProps, 'onClose'>;
      };

interface DetailsContentProps {
    element: DetailsContentElement;
    onClose: () => void;
}

export const DetailsContent = ({ element, onClose }: DetailsContentProps) => {
    if (element.type === 'layout') {
        return <LayoutItemDetails {...element.props} onClose={onClose} />;
    }
    if (element.type === 'media') {
        return <MediaDetails {...element.props} onClose={onClose} />;
    }
    if (element.type === 'option') {
        return <OptionSelectItemDetails {...element.props} onClose={onClose} />;
    }
    if (element.type === 'product') {
        return <ProductItemDetails {...element.props} onClose={onClose} />;
    }
    if (element.type === 'cartItem') {
        return <CartItemDetails {...element.props} onClose={onClose} />;
    }

    return null;
};
