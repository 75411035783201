import { isObjectWithKey, getString, getGenericObject } from '../validateData';
import { getPublicEnv } from '../env/env';
import { MediaType, isMediaType } from './domain/MediaType';
import { fetchUnAuthorizationApi } from '../fetch/fetch';

interface TranslationMedia {
    name: string;
    content: string;
}

function isTranslationMedia(data: unknown): data is TranslationMedia {
    return isObjectWithKey(data) && typeof data.name === 'string' && typeof data.content === 'string';
}

export interface MediaResponse {
    path: string;
    code: MediaType;
    translations: {
        [key: string]: TranslationMedia;
    };
}

export const fetchMedia = async (): Promise<MediaResponse[]> => {
    const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');
    const mediaDomainUrl = await getPublicEnv('MEDIA_DOMAIN_URL');

    const response = await fetchUnAuthorizationApi(`${apiUrl}/shop/cms-plugin/media`, {
        headers: {
            accept: 'application/ld+json',
        },
    });

    if (!isObjectWithKey(response)) {
        throw new Error('Invalid response');
    }

    const data: unknown = response['hydra:member'];

    if (Array.isArray(data)) {
        const formattedMedia = data.map(
            (media): MediaResponse => ({
                path: mediaDomainUrl + getString(media.path),
                code: isMediaType(media.code) ? media.code : 'unknown',
                translations: getGenericObject(media.translations, isTranslationMedia),
            }),
        );

        return formattedMedia;
    }

    return [];
};
