import React, { FC } from 'react';

export const InnerElements: FC = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.30769 8.76L3 7.32L4.84615 3.96L7.15385 3L9 4.92L10.8462 3L13.1538 3.96L15 7.32L12.6923 8.76M5.30769 8.76L5.30769 15L12.6923 15L12.6923 8.76M5.30769 8.76L5.30769 8.28M12.6923 8.76L12.6923 8.28"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
