import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getConfigurationVariant } from '../../../../store/wardrobe/selectors';
import { ItemDetails } from '../ItemDetails/ItemDetails';
import { useLayoutScreen } from '../Layout/components/LayoutItem/LayoutItem';

export interface LayoutItemDetailsProps {
    onClose: () => void;
    configId: string;
}

export const LayoutItemDetails: FC<LayoutItemDetailsProps> = ({ onClose, configId }) => {
    const item = useSelector(getConfigurationVariant(configId));
    const path = useLayoutScreen(configId);

    if (item === null) {
        return null;
    }

    return <ItemDetails name={item.name} description={item.description} image={path} onClose={onClose} />;
};
