import { OptionSelect } from '../../../../services/products/domain/ThreeDParameter';
import { useLanguage } from '../../../Translations/LanguageProvider';
import { ItemDetails } from '../ItemDetails/ItemDetails';

export interface OptionSelectItemDetailsProps {
    option: OptionSelect | null | undefined;
    onClose: () => void;
}

export const OptionSelectItemDetails = ({ option, onClose }: OptionSelectItemDetailsProps) => {
    const { currentLanguage } = useLanguage();

    if (option) {
        return (
            <ItemDetails
                key={option.value}
                name={option.name[currentLanguage] || ''}
                description={option.description[currentLanguage] || ''}
                image={option.image.path}
                onClose={onClose}
            />
        );
    }

    return null;
};
