import React, { FC } from 'react';
import { WrapperStyled } from './Tabs.styled';
import { Tabs as TabsCommon } from '@formify/frontend-components';
import { TabsProps } from '@formify/frontend-components/dist/components/Tabs/Tabs';

export const Tabs = <T extends string>({
    items,
    selectedTab,
    onSelect,
    disabled,
    children,
}: TabsProps<T>): ReturnType<FC> => (
    <WrapperStyled>
        <TabsCommon items={items} disabled={disabled} selectedTab={selectedTab} onSelect={onSelect}>
            <div onClick={(e) => e.stopPropagation()}>{children}</div>
        </TabsCommon>
    </WrapperStyled>
);
