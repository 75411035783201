import { ProductCode, isProductCodes } from '../../services/products/domain/ProductCodes';
import { ColumnConfiguration, ColumnConfigurationDetails, ConfigurationApi } from '../../types/Configuration';
import { AllColumnConfigurations } from '../../store/configurations/reducer';
import { CustomOptionsValue } from '../../types/Project';
import { getPublicEnv } from '../env/env';
import { fetchUnAuthorizationApi } from '../fetch/fetch';
import {
    isObjectWithKey,
    isNumber,
    getGenericValue,
    isImage,
    getString,
    isString,
    isGenericArray,
    getGenericArray,
    getBoolean,
} from '../validateData';

type BaseProduct = {
    id: number;
    code: ProductCode;
    threeDParameters: CustomOptionsValue[];
};

type DynamicBox = {
    product: BaseProduct;
    height: number;
};

type FixedElement = {
    product: BaseProduct;
    height: number;
    priority: number;
};

function isBaseProduct(data: unknown): data is BaseProduct {
    return isObjectWithKey(data) && typeof data.id === 'number' && isProductCodes(data.code);
}

function isDynamicBox(data: unknown): data is DynamicBox {
    return isObjectWithKey(data) && typeof data.height === 'number' && isBaseProduct(data.product);
}

function isFixedElement(data: unknown): data is FixedElement {
    return isObjectWithKey(data) && typeof data.height === 'number' && isBaseProduct(data.product);
}

const isDepthVariant = (data: unknown): data is ConfigurationApi['depthVariants'][0] =>
    isObjectWithKey(data) &&
    isString(data.description) &&
    isImage(data.image) &&
    isDynamicBox(data.dynamicBox) &&
    isGenericArray(data.fixedElements, isFixedElement);

export const fetchConfigurations = async (): Promise<AllColumnConfigurations> => {
    const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');
    const mediaDomainUrl = await getPublicEnv('MEDIA_DOMAIN_URL');

    const configurations = await fetchUnAuthorizationApi(`${apiUrl}/shop/layouts`, {
        headers: {
            accept: 'application/json',
        },
    });

    if (Array.isArray(configurations)) {
        const formattedConfigurations: ConfigurationApi[] = configurations.map(
            (configuration): ConfigurationApi => ({
                id: getGenericValue(configuration.id, isNumber, -1),
                name: getString(configuration.name),
                isEnabled: getBoolean(configuration.isEnabled),
                depthVariants: getGenericArray(configuration.depthVariants, isDepthVariant),
            }),
        );

        return formattedConfigurations.reduce<AllColumnConfigurations>((acc, config) => {
            let isCorrectForCorner = true;

            const depthVariants = config.depthVariants.reduce<ColumnConfiguration['depthVariants']>((acc, value) => {
                const key = value.depthValue;

                acc[key] = {
                    name: config.name,
                    image: `${mediaDomainUrl}${value.image?.path}`,
                    description: value.description,
                    dynamicBox: value.dynamicBox,
                    fixedElements: value.fixedElements,
                };

                const haveDrawer = value.fixedElements.some(
                    (fixedElement) =>
                        fixedElement.product.code === 'double_drawer' || fixedElement.product.code === 'quad_drawer',
                );

                if (isCorrectForCorner && haveDrawer) {
                    isCorrectForCorner = false;
                }

                return acc;
            }, {});

            if (Object.keys(depthVariants).length !== 0) {
                acc[config.id] = {
                    ...depthVariants,
                    isEnabled: config.isEnabled,
                    isCorrectForCorner,
                    depthVariants,
                    hash: hashDepthVariants(depthVariants),
                };
            }

            return acc;
        }, {});
    }

    return {};
};

function hashDepthVariants(depthVariants: { [depth: number]: ColumnConfigurationDetails }) {
    const string = JSON.stringify(depthVariants);
    let hash = 0;

    for (let i = 0; i < string.length; i++) {
        const code = string.charCodeAt(i);

        hash = (hash << 5) - hash + code;
        hash = hash & hash; // Convert to 32bit integer
    }

    return Math.abs(hash).toString(32);
}
