import React, { FC, useCallback, useState } from 'react';
import { Button } from '@formify/frontend-components';
import { ChevronLeftIcon } from '../../../icons/ChevronLeft/ChevronLeft';
import { BackButtonWrapper, Wrapper } from './SaveProject.styled';
import { ScrollableContentInSidePanel } from '../../../Sidebar/components/ScrollableContentInSidePanel/ScrollableContentInSidePanel';
import { useCurrentProjectSave } from '../../../../hooks/useCurrentProjectSave';
import { SaveProjectForm } from './SaveProjectForm';
import { useLanguage } from '../../../Translations/LanguageProvider';

interface SaveProjectProps {
    onClose: () => void;
}

export const SaveProject: FC<SaveProjectProps> = ({ onClose }) => {
    const [savedProjectId, setSavedProjectId] = useState<null | string>(null);
    const saveCurrentProject = useCurrentProjectSave();
    const { t } = useLanguage();

    const goBack = useCallback(() => {
        onClose();
        setSavedProjectId(null);
    }, [onClose]);

    return (
        <>
            <ScrollableContentInSidePanel title={t('Save Your Design')}>
                <Wrapper>
                    <SaveProjectForm
                        saved={!!savedProjectId}
                        goBack={goBack}
                        saveCurrentProject={async (email: string) => {
                            const { id } = await saveCurrentProject(email);

                            setSavedProjectId(id);
                        }}
                    />
                </Wrapper>
            </ScrollableContentInSidePanel>
            <BackButtonWrapper>
                <Button variant="outlined-white" icon={<ChevronLeftIcon />} onClick={goBack}></Button>
            </BackButtonWrapper>
        </>
    );
};
