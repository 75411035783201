import { SimplifyMedia } from '../../../../store/media/reducer';
import { ItemDetails } from '../ItemDetails/ItemDetails';

export interface MediaDetailsProps {
    details: SimplifyMedia | null | undefined;
    onClose: () => void;
    showImage?: boolean;
}

export const MediaDetails = ({ details, onClose, showImage = true }: MediaDetailsProps) => {
    if (details) {
        return (
            <ItemDetails
                key={details.name}
                name={details.name}
                description={details.description}
                image={showImage ? details.image : undefined}
                onClose={onClose}
            />
        );
    }

    return null;
};
