import back from '../../resources/textures/wardrobe/ao/cabinet_back_AO_1024.png';
import bottom from '../../resources/textures/wardrobe/ao/cabinet_bottom_AO_1024.png';
import left from '../../resources/textures/wardrobe/ao/cabinet_left_AO_1024.png';
import wall from '../../resources/textures/wardrobe/ao/ao_wall.png';
import wallTransparent from '../../resources/textures/wardrobe/ao/ao_wall_t.png';
import silhouette from '../../resources/textures/wardrobe/silhouette_t.png';
import envMap from '../../resources/textures/wardrobe/envMap.jpg';
import { RootState } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';

export const aoTextures: RootState['ao'] = {
    back: back.src,
    bottom: bottom.src,
    left: left.src,
    wall: wall.src,
    wallTransparent: wallTransparent.src,
    silhouette: silhouette.src,
    envMap: envMap.src,
};

export const modelsUrls: RootState['modelsUrls'] = {
    // Shelve
    shoes1: '/model/binary/shoes 1.glb',
    shoes2: '/model/binary/shoes 2.glb',
    shoes3: '/model/binary/shoes 3.glb',
    shoes4: '/model/binary/shoes 4.glb',
    box1: '/model/binary/Square box.glb',
    box2: '/model/binary/round box.glb',
    stack1: '/model/binary/Stack 1.glb',
    stack2: '/model/binary/Stack 2.glb',
    hat: '/model/binary/hat.glb',
    bag: '/model/binary/Bag.glb',

    // ClothesElements
    Cloth1: '/model/binary/Cloth 1.glb',
    Cloth2: '/model/binary/Cloth 2.glb',
    Cloth3: '/model/binary/Cloth 3.glb',
    coat: '/model/binary/coat.glb',
    pants1: '/model/binary/pants 1.glb',
    pants2: '/model/binary/pants 2.glb',
};
