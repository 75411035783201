import React, { FC, useState } from 'react';
import { AddIcon } from '../icons/Add/Add';
import { SaveIcon } from '../icons/Save/Save';
import { DoorIcon } from '../icons/Door/Door';
import { DividerStyled, Wrapper } from './ContextMenu.styled';
import {
    hideDoors,
    hideInnerElements,
    showDoors,
    showInnerElements,
} from '@formify/frontend-wardrobe-renderer/dist/modules/actions';
import { useRendererSelector, useWardrobeDispatch } from '@formify/frontend-wardrobe-renderer/dist/modules/context';
import { ScrollableContentInSidePanel } from '../Sidebar/components/ScrollableContentInSidePanel/ScrollableContentInSidePanel';
import { SaveProject } from './components/SaveProject/SaveProject';
import { CarouselIndexes, SliderWithDetails } from '../SidePanel/components/SliderWithDetails/SliderWithDetails';
import { Button } from '@formify/frontend-components';
import { getDoorVisibleThreeJsSelector, getInnerItemsVisibleThreeJsSelector } from '../ActionButtons/ActionButtons';
import { mainRendererContext } from '../../rendererContexts/mainRendererContext';
import { InnerElements } from '../icons/InnerElements/InnerElements';
import { useResetProject } from '../../hooks/useResetProject';
import { Tr } from '../Translations/Tr';

interface ContextMenuProps {
    onClose: () => void;
    isOpen: boolean;
}

export const ContextMenu: FC<ContextMenuProps> = ({ onClose, isOpen }) => {
    const dispatchRenderer = useWardrobeDispatch(mainRendererContext);
    const doorVisible = useRendererSelector(getDoorVisibleThreeJsSelector, mainRendererContext);
    const innerItemsVisible = useRendererSelector(getInnerItemsVisibleThreeJsSelector, mainRendererContext);
    const [selectedSlideIndex, setSelectedSlideIndex] = useState(CarouselIndexes.Main);
    const resetProject = useResetProject();

    return (
        <SliderWithDetails
            zIndex={11}
            overlay={isOpen}
            isOpen={isOpen}
            onClose={() => {
                setSelectedSlideIndex(CarouselIndexes.Main);
                onClose();
            }}
            isMobileFullWidth={selectedSlideIndex === CarouselIndexes.Details}
            selectedSlideIndex={selectedSlideIndex}
            details={
                selectedSlideIndex === CarouselIndexes.Details ? (
                    <SaveProject onClose={() => setSelectedSlideIndex(CarouselIndexes.Main)} />
                ) : null
            }
        >
            <ScrollableContentInSidePanel dataTestId="dotSideMenu" title="">
                <Wrapper>
                    <Button
                        variant="icon-text"
                        className="my-16"
                        icon={<SaveIcon />}
                        onClick={() => setSelectedSlideIndex(CarouselIndexes.Details)}
                        dataTestId="save-my-design"
                    >
                        <Tr labelKey="Save my design" defaultValue="Save my design" />
                    </Button>
                    <Button
                        variant="icon-text"
                        className="my-16"
                        icon={<AddIcon />}
                        onClick={() => {
                            resetProject();
                            onClose();
                        }}
                    >
                        <Tr labelKey="Create new" defaultValue="Create new" />
                    </Button>
                    <DividerStyled />
                    <Button
                        data-test="door_button"
                        data-test-state={doorVisible ? 'shown' : 'hidden'}
                        variant="icon-text"
                        className="my-16"
                        icon={<DoorIcon />}
                        onClick={() => {
                            dispatchRenderer(doorVisible ? hideDoors() : showDoors());
                            onClose();
                        }}
                    >
                        {doorVisible ? (
                            <Tr labelKey="hide_doors" defaultValue="Hide" />
                        ) : (
                            <Tr labelKey="Show doors" defaultValue="Show doors" />
                        )}
                    </Button>

                    <Button
                        variant="icon-text"
                        className="my-16"
                        icon={<InnerElements />}
                        onClick={() => {
                            dispatchRenderer(innerItemsVisible ? hideInnerElements() : showInnerElements());
                            onClose();
                        }}
                    >
                        {innerItemsVisible ? (
                            <Tr labelKey="hide_clothes" defaultValue="Hide" />
                        ) : (
                            <Tr labelKey="Show clothes" defaultValue="Show clothes" />
                        )}
                    </Button>
                </Wrapper>
            </ScrollableContentInSidePanel>
        </SliderWithDetails>
    );
};
